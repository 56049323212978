<template>
  <div>
    <Header></Header>
    <router-view :class="[screenWidth > 640 ? 'content' : '']"></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/head.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      screenWidth: null,
    };
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
  },
};
</script>

<style lang="less">
.content {
  margin-top: 80px;
}
</style>
