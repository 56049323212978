<template>
  <div
    :class="scrollTop > 80 ? 'scrollTopClass' : 'scrollBottomClass'"
    v-if="screenWidth > 640"
    class="topNav"
  >
    <!-- 公司图标 -->
    <div class="companyLogo">
      <img v-if="scrollTop <= 80" src="@/assets/image/logo.png" />
      <img v-else-if="scrollTop > 80" src="@/assets/image/active_logo.png" />
    </div>
    <!-- 列表 -->
    <div class="navList">
      <ul v-if="scrollTop <= 80">
        <template v-for="(item, index) in navList">
          <router-link :to="item.path" :key="index" v-if="!item.meta.hidden">
            <li :class="$route.path === item.path ? 'header_active' : ''">
              {{ item.meta.label }}
            </li>
          </router-link>
        </template>
      </ul>
      <ul v-else-if="scrollTop > 80">
        <template v-for="(item, index) in navList">
          <router-link :to="item.path" :key="index" v-if="!item.meta.hidden">
            <li
              class="li_change"
              :class="$route.path === item.path ? 'header_activeChange' : ''"
            >
              {{ item.meta.label }}
            </li>
          </router-link>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    navList() {
      return this.$router.options.routes[0].children;
    },
  },
  data() {
    return {
      screenWidth: null,
      scrollTop: 0,
    };
  },
  watch: {},
  created() {
    this.screenWidth = document.documentElement.clientWidth;
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle);
  },
  methods: {
    scrollHandle(e) {
      this.scrollTop = e.srcElement.scrollingElement.scrollTop;
    },
  },
};
</script>

<style lang="less">
.topNav {
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 200;

  .companyLogo {
    width: 90px;
    height: 48px;
    margin: 16px 378px 0 360px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .navList {
    ul {
      display: flex;
      a {
        text-decoration: none;
      }
      li {
        height: 34px;
        margin-top: 23px;
        margin-right: 50px;
        color: #333333;
        cursor: pointer;
        font-size: 1rem;
        font-family: pf_cg;
        padding: 0 5px;
        box-sizing: border-box;
        position: relative;
      }

      .li_change {
        color: #ffffff;
      }

      .header_active {
        font-family: PingFang Medium;
        color: #0052d9;
        border-bottom: 2px solid #0052d9;
      }

      .header_activeChange {
        color: #ffffff;
        border-bottom: 2px solid #ffffff;
      }

      li:after {
        content: "";
        width: 0;
        height: 2px;
        background: #0052d9;
        position: absolute;
        top: 100%;
        left: 50%;
        transition: all 0.8s;
      }

      .li_change:after {
        background: #ffffff;
      }

      li:hover {
        color: #0052d9;
      }

      .li_change:hover {
        color: #ffffff;
      }

      li:hover:after {
        left: 0;
        width: 100%;
      }

      .li_change:hover:after {
        left: 0;
        width: 100%;
      }
    }
  }
}

.scrollTopClass {
  animation: scrollTopChange 0.5s forwards alternate;
}

@keyframes scrollTopChange {
  from {
    background-size: 100% 100%;
    background-color: #fff;
    height: 0px;
  }
  to {
    background-color: #349fe7;
    background-size: 300% 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  }
}

.scrollBottomClass {
  animation: scrollBottomChange 0.5s forwards alternate;
}

@keyframes scrollBottomChange {
  from {
    background-size: 300% 100%;
    background-color: #349fe7;
  }
  to {
    height: 0px;
    background-size: 100% 100%;
    background-color: #fff;
  }
}
</style>
