<template>
  <div v-if="screenWidth > 640" class="foot">
    <div class="banner">
      <div class="list">
        <ul>
          <li
            v-for="(item, index) in titleList"
            :key="index"
            @click="AlinkFather(item.title)"
          >
            {{ item.title }}
          </li>
        </ul>
        <ul>
          <li v-for="(item, index) in titleList" :key="index">
            <ul v-for="(i, index) in item.list" :key="index">
              <li>
                <span @click="Alink(i)">{{ i }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="QRCode">
        <div class="image">
          <img src="../assets/image/gfewm.png" />
        </div>
        <div class="txe">官方公众号</div>
      </div>
      <div class="record">
        Copyright©2014 - 2023 江苏星闪世图科技（集团）有限公司 All
        RightReserved&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a href=" https://beian.miit.gov.cn/#/ntegrated/recordQuery" target="_blank"
          >苏ICP备2023029535号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titleList: [
        {
          title: "关于我们",
          list: ["集团简介", "公司分布", "发展历程", "行业奖项"],
        },
        {
          title: "企业资讯",
          list: ["企业动态", "行业新闻"],
          // list: ["新闻中心", "企业动态", "行业新闻"],
        },
        {
          title: "产品服务",
          list: ["无人机", "巡线机器人", "AI盒子", "卫星高精度定位终端"],
        },
        {
          title: "加入我们",
          // list: ["企业文化", "人才招聘"],
          list: ["人才招聘"],
        },
        {
          title: "解决方案",
          list: ["智慧能源", "智慧交通", "智慧水务", "智慧文旅", "数字乡村"],
        },
      ],
      screenWidth: null,
    };
  },
  created() {
    this.screenWidth = document.documentElement.clientWidth;
  },
  mounted() {},
  methods: {
    // 标题
    AlinkFather(val) {
      if (val == "关于我们" || val == "加入我们") {
        this.$router.push("/About");
      } else if (val == "企业资讯") {
        this.$router.push("/enterpriseInfo");
      } else if (val == "产品服务") {
        this.$router.push("/service");
      } else if (val == "解决方案") {
        this.$router.push("/solution");
      }
    },

    // 菜单
    Alink(val) {
      if (val == "集团简介") {
        window.location.href = "/groupProfile#briefInt";
      } else if (val == "公司分布") {
        window.location.href = "/groupProfile#address";
      } else if (val == "发展历程") {
        window.location.href = "/groupProfile#developmentHistory";
      } else if (val == "行业奖项") {
        window.location.href = "/groupProfile#prize";
      } else if (val == "企业动态") {
        this.$router.push({
          path: "enterpriseInfo",
          query: {
            type: 0,
          },
        });
      } else if (val == "行业新闻") {
        this.$router.push({
          path: "enterpriseInfo",
          query: {
            type: 1,
          },
        });
      } else if (val == "人才招聘") {
        this.$router.push({
          path: "About",
          query: {
            id: 0,
          },
        });
      } else if (val == "智慧能源") {
        this.$router.push({
          path: "/solution",
          query: {
            type: 0,
            digitalTwinsId: 1,
          },
        });
      } else if (val == "智慧交通") {
        this.$router.push({
          path: "/solution",
          query: {
            type: 1,
            digitalTwinsId: 2,
          },
        });
      } else if (val == "智慧水务") {
        this.$router.push({
          path: "/solution",
          query: {
            type: 2,
            digitalTwinsId: 3,
          },
        });
      } else if (val == "智慧文旅") {
        this.$router.push({
          path: "/solution",
          query: {
            type: 3,
            digitalTwinsId: 4,
          },
        });
      } else if (val == "数字乡村") {
        this.$router.push({
          path: "/solution",
          query: {
            type: 4,
            digitalTwinsId: 5,
          },
        });
      } else if (val == "无人机") {
        window.location.href = "/service#list0";
      } else if (val == "巡线机器人") {
        window.location.href = "/service#list1";
      } else if (val == "AI盒子") {
        window.location.href = "/service#list2";
      } else if (val == "卫星高精度定位终端") {
        window.location.href = "/service#list3";
      }
    },
  },
};
</script>

<style lang="less">
.foot {
  width: 100%;
  height: 450px;
  background-color: #f6f6f6;
  padding: 0 360px;
  box-sizing: border-box;

  .banner {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .list {
      // width: 850px;
      margin-top: 80px;

      ul {
        display: flex;

        li {
          color: #333333;
          font-size: 1.25rem;
          font-family: PingFang Medium;
          width: 170px;
          cursor: pointer;

          ul {
            li {
              margin-top: 15px;
              color: #666666;
              font-family: pf_cg;
              font-size: 1rem;
              cursor: pointer;

              span:hover {
                border-bottom: 1px solid #666666;
              }
            }
          }
        }
      }
    }

    .QRCode {
      margin: 80px 0 0 194px;

      .image {
        width: 156px;
        height: 156px;
      }

      .txe {
        width: 156px;
        text-align: center;
        color: #666666;
        font-size: 1.1rem;
        font-family: pf_cg;
        margin-top: 10px;
      }
    }

    .record {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 20px;
      color: #666666;
      font-size: 0.875rem;
      font-family: pf_cg;
    }
  }
}
</style>
